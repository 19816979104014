"use strict";
import Bold from "@tiptap/extension-bold";
export default Bold.extend({
  parseHTML() {
    return [
      {
        tag: "strong"
      },
      {
        tag: "b",
        getAttrs: (node) => node.style.fontWeight !== "normal" && node.style.fontWeight !== "400" && null
      },
      {
        tag: "span",
        getAttrs: (node) => /^(bold(er)?|[5-9]\d{2,})$/.test(node.style.fontWeight) && null
      }
    ];
  }
});
