"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Mention from "@tiptap/extension-mention";
import { PluginKey } from "prosemirror-state";
import { createSuggestionRenderer, createNodeView } from "src/decidim/editor/common/suggestion";
export const HashtagPluginKey = new PluginKey("hashtag");
const searchHashtags = (queryText) => __async(void 0, null, function* () {
  return fetch(window.Decidim.config.get("api_path"), {
    method: "POST",
    cache: "no-cache",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ query: `{ hashtags(name:"${queryText}") {name} }` })
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error("Could not retrieve data");
  }).then((json) => json.data.hashtags || []);
});
export default Mention.extend({
  name: "hashtag",
  addOptions() {
    var _a;
    const options = (_a = this.parent) == null ? void 0 : _a.call(this);
    const suggestion = options == null ? void 0 : options.suggestion;
    return __spreadProps(__spreadValues({}, options), {
      renderLabel({ node }) {
        var _a2;
        return `${(_a2 = node.attrs.label) != null ? _a2 : node.attrs.id}`;
      },
      suggestion: __spreadProps(__spreadValues({}, suggestion), {
        char: "#",
        pluginKey: HashtagPluginKey,
        items: (_0) => __async(this, [_0], function* ({ query }) {
          if (query.length < 2) {
            return [];
          }
          const data = yield searchHashtags(query);
          const sorted = data.sort((tag) => tag.name);
          return sorted.slice(0, 5);
        }),
        render: createSuggestionRenderer(this, {
          itemConverter: (tag) => {
            return { label: `#${tag.name}` };
          }
        })
      })
    });
  },
  addNodeView() {
    return createNodeView(this);
  }
});
