"use strict";
import { Extension } from "@tiptap/core";
const allowedNodeTypes = ["heading", "paragraph"];
const allowedNodeActive = (editor) => allowedNodeTypes.some((type) => editor.isActive(type));
const closestAllowedNode = (position) => {
  let depth = position.depth;
  while (depth > 0) {
    const node = position.node(depth);
    if (allowedNodeTypes.some((type) => node.type.name === type)) {
      return node;
    }
    depth -= 1;
  }
  return null;
};
export default Extension.create({
  name: "indent",
  addOptions() {
    return {
      minIndentLevel: 0,
      maxIndentLevel: 10,
      HTMLAttributes: {}
    };
  },
  addGlobalAttributes() {
    const defaultIndentLevel = 0;
    return [
      {
        types: allowedNodeTypes,
        attributes: {
          indent: {
            default: defaultIndentLevel,
            renderHTML: (attributes) => {
              if (attributes.indent < 1) {
                return {};
              }
              return { class: `editor-indent-${attributes.indent}` };
            },
            parseHTML: (element) => {
              const regexp = /^(editor|ql)-indent-([0-9]+)/;
              const indentClass = Array.from(element.classList).find((cls) => regexp.test(cls));
              if (!indentClass) {
                return defaultIndentLevel;
              }
              return parseInt(indentClass.match(regexp)[2], 10);
            }
          }
        }
      }
    ];
  },
  addCommands() {
    const updateIndent = (modifier, { editor, state, dispatch, commands }) => {
      if (!allowedNodeActive(editor)) {
        return false;
      }
      const node = closestAllowedNode(state.selection.$head);
      if (node === null) {
        return false;
      }
      const indent = node.attrs.indent + modifier;
      if (indent < this.options.minIndentLevel || indent > this.options.maxIndentLevel) {
        return false;
      }
      if (dispatch) {
        return commands.updateAttributes(node.type.name, { indent });
      }
      return true;
    };
    return {
      indent: () => ({ editor, state, commands, dispatch }) => {
        if (editor.isActive("listItem")) {
          if (dispatch) {
            return commands.sinkListItem("listItem");
          }
          return true;
        }
        return updateIndent(1, { editor, state, dispatch, commands });
      },
      outdent: () => ({ editor, state, commands, dispatch }) => {
        if (editor.isActive("listItem")) {
          if (state.selection.$head.depth === 3) {
            return false;
          }
          if (dispatch) {
            return commands.liftListItem("listItem");
          }
          return true;
        }
        return updateIndent(-1, { editor, state, dispatch, commands });
      }
    };
  },
  addKeyboardShortcuts() {
    const indent = () => {
      if (!this.editor.can().indent()) {
        return false;
      }
      return this.editor.commands.indent();
    };
    const outdent = () => {
      if (!this.editor.can().outdent()) {
        return false;
      }
      return this.editor.commands.outdent();
    };
    return {
      Tab: indent,
      "Shift-Tab": outdent,
      Backspace: () => {
        if (this.editor.isActive("listItem")) {
          return false;
        }
        if (this.editor.state.selection.$head.parentOffset > 0) {
          return false;
        }
        return outdent();
      },
      "Mod-]": indent,
      "Mod-[": outdent
    };
  }
});
