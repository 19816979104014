"use strict";
import { getDictionary } from "src/decidim/i18n";
import html from "src/decidim/editor/utilities/html";
import iconsUrl from "images/decidim/remixicon.symbol.svg";
const createIcon = (iconName) => {
  return `<svg class="editor-toolbar-icon" role="img" aria-hidden="true">
    <use href="${iconsUrl}#ri-${iconName}" />
  </svg>`;
};
const createEditorToolbarGroup = () => {
  return html("div").dom((el) => el.classList.add("editor-toolbar-group"));
};
const createEditorToolbarToggle = (editor, { type, label, icon, action, activatable = true }) => {
  return html("button").dom((ctrl) => {
    ctrl.classList.add("editor-toolbar-control");
    ctrl.dataset.editorType = type;
    if (activatable) {
      ctrl.dataset.editorSelectionType = type;
    }
    ctrl.type = "button";
    ctrl.ariaLabel = label;
    ctrl.title = label;
    ctrl.innerHTML = createIcon(icon);
    ctrl.addEventListener("click", (ev) => {
      ev.preventDefault();
      editor.commands.focus();
      action();
    });
  });
};
const createEditorToolbarSelect = (editor, { type, label, options, action, activatable = true }) => {
  return html("select").dom((ctrl) => {
    ctrl.classList.add("editor-toolbar-control", "!pr-8");
    ctrl.dataset.editorType = type;
    if (activatable) {
      ctrl.dataset.editorSelectionType = type;
    }
    ctrl.ariaLabel = label;
    ctrl.title = label;
    options.forEach(({ label: optionLabel, value }) => {
      const option = document.createElement("option");
      option.setAttribute("value", value);
      option.textContent = optionLabel;
      ctrl.appendChild(option);
    });
    ctrl.addEventListener("change", () => {
      editor.commands.focus();
      action(ctrl.value);
    });
  });
};
export default function createEditorToolbar(editor) {
  const i18n = getDictionary("editor.toolbar");
  const supported = { nodes: [], marks: [], extensions: [] };
  editor.extensionManager.extensions.forEach((ext) => {
    if (ext.type === "node") {
      supported.nodes.push(ext.name);
    } else if (ext.type === "mark") {
      supported.marks.push(ext.name);
    } else if (ext.type === "extension") {
      supported.extensions.push(ext.name);
    }
  });
  const toolbar = html("div").dom((el) => el.classList.add("editor-toolbar")).append(
    // Text style controls
    createEditorToolbarGroup(editor).append(
      createEditorToolbarSelect(editor, {
        type: "heading",
        label: i18n["control.heading"],
        options: [
          { value: "normal", label: i18n["textStyle.normal"] },
          { value: 2, label: i18n["textStyle.heading"].replace("%level%", 2) },
          { value: 3, label: i18n["textStyle.heading"].replace("%level%", 3) },
          { value: 4, label: i18n["textStyle.heading"].replace("%level%", 4) },
          { value: 5, label: i18n["textStyle.heading"].replace("%level%", 5) },
          { value: 6, label: i18n["textStyle.heading"].replace("%level%", 6) }
        ],
        action: (value) => {
          if (value === "normal") {
            editor.commands.setParagraph();
          } else {
            editor.commands.toggleHeading({ level: parseInt(value, 10) });
          }
        }
      }).render(supported.nodes.includes("heading"))
    )
  ).append(
    // Basic styling controls
    createEditorToolbarGroup(editor).append(
      createEditorToolbarToggle(editor, {
        type: "bold",
        icon: "bold",
        label: i18n["control.bold"],
        action: () => editor.commands.toggleBold()
      }).render(supported.marks.includes("bold")),
      createEditorToolbarToggle(editor, {
        type: "italic",
        icon: "italic",
        label: i18n["control.italic"],
        action: () => editor.commands.toggleItalic()
      }).render(supported.marks.includes("italic")),
      createEditorToolbarToggle(editor, {
        type: "underline",
        icon: "underline",
        label: i18n["control.underline"],
        action: () => editor.commands.toggleUnderline()
      }).render(supported.marks.includes("underline")),
      createEditorToolbarToggle(editor, {
        type: "hardBreak",
        icon: "text-wrap",
        label: i18n["control.hardBreak"],
        activatable: false,
        action: () => editor.commands.setHardBreak()
      }).render(supported.nodes.includes("hardBreak"))
    )
  ).append(
    // List controls
    createEditorToolbarGroup(editor).append(
      createEditorToolbarToggle(editor, {
        type: "orderedList",
        icon: "list-ordered",
        label: i18n["control.orderedList"],
        action: () => editor.commands.toggleOrderedList()
      }).render(supported.nodes.includes("orderedList")),
      createEditorToolbarToggle(editor, {
        type: "bulletList",
        icon: "list-unordered",
        label: i18n["control.bulletList"],
        action: () => editor.commands.toggleBulletList()
      }).render(supported.nodes.includes("bulletList"))
    )
  ).append(
    // Link and erase styles
    createEditorToolbarGroup(editor).append(
      createEditorToolbarToggle(editor, {
        type: "link",
        icon: "link",
        label: i18n["control.link"],
        action: () => editor.commands.linkDialog()
      }).render(supported.marks.includes("link")),
      createEditorToolbarToggle(editor, {
        type: "common:eraseStyles",
        icon: "eraser-line",
        label: i18n["control.common.eraseStyles"],
        activatable: false,
        action: () => {
          if (editor.isActive("link") && editor.view.state.selection.empty) {
            const originalPos = editor.view.state.selection.anchor;
            editor.chain().focus().extendMarkRange("link").unsetLink().setTextSelection(originalPos).run();
          } else {
            editor.chain().focus().clearNodes().unsetAllMarks().run();
          }
        }
      }).render(
        supported.nodes.includes("heading") || supported.marks.includes("bold") || supported.marks.includes("italic") || supported.marks.includes("underline") || supported.nodes.includes("hardBreak") || supported.nodes.includes("orderedList") || supported.nodes.includes("bulletList") || supported.marks.includes("link")
      )
    )
  ).append(
    // Block styling
    createEditorToolbarGroup(editor).append(
      createEditorToolbarToggle(editor, {
        type: "codeBlock",
        icon: "code-line",
        label: i18n["control.codeBlock"],
        action: () => editor.commands.toggleCodeBlock()
      }).render(supported.nodes.includes("codeBlock")),
      createEditorToolbarToggle(editor, {
        type: "blockquote",
        icon: "double-quotes-l",
        label: i18n["control.blockquote"],
        action: () => editor.commands.toggleBlockquote()
      }).render(supported.nodes.includes("blockquote"))
    )
  ).append(
    // Indent and outdent
    createEditorToolbarGroup(editor).append(
      createEditorToolbarToggle(editor, {
        type: "indent:indent",
        icon: "indent-increase",
        label: i18n["control.indent.indent"],
        activatable: false,
        action: () => editor.commands.indent()
      }).render(supported.extensions.includes("indent")),
      createEditorToolbarToggle(editor, {
        type: "indent:outdent",
        icon: "indent-decrease",
        label: i18n["control.indent.outdent"],
        activatable: false,
        action: () => editor.commands.outdent()
      }).render(supported.extensions.includes("indent"))
    )
  ).append(
    // Multimedia
    createEditorToolbarGroup(editor).append(
      createEditorToolbarToggle(editor, {
        type: "videoEmbed",
        icon: "video-line",
        label: i18n["control.videoEmbed"],
        action: () => editor.commands.videoEmbedDialog()
      }).render(supported.nodes.includes("videoEmbed")),
      createEditorToolbarToggle(editor, {
        type: "image",
        icon: "image-line",
        label: i18n["control.image"],
        action: () => editor.commands.imageDialog()
      }).render(supported.nodes.includes("image"))
    )
  ).render();
  const selectionControls = toolbar.querySelectorAll(".editor-toolbar-control[data-editor-selection-type]");
  const headingSelect = toolbar.querySelector(".editor-toolbar-control[data-editor-type='heading']");
  const selectionUpdated = () => {
    if (editor.isActive("heading")) {
      const { level } = editor.getAttributes("heading");
      headingSelect.value = `${level}`;
    } else if (headingSelect) {
      headingSelect.value = "normal";
    }
    selectionControls.forEach((ctrl) => {
      if (editor.isActive(ctrl.dataset.editorSelectionType)) {
        ctrl.classList.add("active");
      } else {
        ctrl.classList.remove("active");
      }
    });
  };
  editor.on("update", selectionUpdated);
  editor.on("selectionUpdate", selectionUpdated);
  return toolbar;
}
;
