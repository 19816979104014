"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Link from "@tiptap/extension-link";
import { Plugin } from "prosemirror-state";
import { getDictionary } from "src/decidim/i18n";
import InputDialog from "src/decidim/editor/common/input_dialog";
import createBubbleMenu from "src/decidim/editor/extensions/link/bubble_menu";
export default Link.extend({
  addStorage() {
    return { bubbleMenu: null };
  },
  onCreate() {
    var _a;
    (_a = this.parent) == null ? void 0 : _a.call(this);
    this.storage.bubbleMenu = createBubbleMenu(this.editor);
  },
  onDestroy() {
    var _a;
    (_a = this.parent) == null ? void 0 : _a.call(this);
    this.storage.bubbleMenu.destroy();
    this.storage.bubbleMenu = null;
  },
  addOptions() {
    var _a;
    return __spreadProps(__spreadValues({}, (_a = this.parent) == null ? void 0 : _a.call(this)), {
      allowTargetControl: false,
      HTMLAttributes: {
        target: "_blank",
        class: null
      }
    });
  },
  addCommands() {
    var _a;
    const i18n = getDictionary("editor.extensions.link");
    return __spreadProps(__spreadValues({}, (_a = this.parent) == null ? void 0 : _a.call(this)), {
      toggleLinkBubble: () => ({ dispatch }) => {
        if (dispatch) {
          if (this.editor.isActive("link")) {
            this.storage.bubbleMenu.show();
            return true;
          }
          this.storage.bubbleMenu.hide();
          return false;
        }
        return this.editor.isActive("link");
      },
      linkDialog: () => (_0) => __async(this, [_0], function* ({ dispatch, commands }) {
        if (dispatch) {
          commands.extendMarkRange("link");
          this.storage.bubbleMenu.hide();
          const { allowTargetControl } = this.options;
          let { href, target } = this.editor.getAttributes("link");
          const inputs = { href: { type: "text", label: i18n.hrefLabel } };
          if (allowTargetControl) {
            inputs.target = {
              type: "select",
              label: i18n.targetLabel,
              options: [
                { value: "", label: i18n["targets.default"] },
                { value: "_blank", label: i18n["targets.blank"] }
              ]
            };
          }
          const linkDialog = new InputDialog(this.editor, { inputs });
          const dialogState = yield linkDialog.toggle({ href, target });
          href = linkDialog.getValue("href");
          target = linkDialog.getValue("target");
          if (!allowTargetControl) {
            target = "_blank";
          } else if (!target || target.length < 1) {
            target = null;
          }
          if (dialogState !== "save") {
            this.editor.chain().focus(null, { scrollIntoView: false }).toggleLinkBubble().run();
            return false;
          }
          if (!href || href.trim().length < 1) {
            return this.editor.chain().focus(null, { scrollIntoView: false }).unsetLink().run();
          }
          return this.editor.chain().focus(null, { scrollIntoView: false }).setLink({ href, target }).toggleLinkBubble().run();
        }
        return true;
      })
    });
  },
  addProseMirrorPlugins() {
    var _a;
    const editor = this.editor;
    return [
      ...((_a = this.parent) == null ? void 0 : _a.call(this)) || {},
      new Plugin({
        props: {
          handleDoubleClick() {
            if (!editor.isActive("link")) {
              return false;
            }
            editor.chain().focus().linkDialog().run();
            return true;
          }
        }
      })
    ];
  }
});
