"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { mergeAttributes } from "@tiptap/core";
import Image from "@tiptap/extension-image";
import { Plugin } from "prosemirror-state";
import { getDictionary } from "src/decidim/i18n";
import { fileNameToTitle } from "src/decidim/editor/utilities/file";
import createNodeView from "src/decidim/editor/extensions/image/node_view";
import UploadDialog from "src/decidim/editor/common/upload_dialog";
const createImageUploadDialog = (editor, { uploadDialogSelector }) => {
  const i18nUpload = getDictionary("editor.upload");
  return new UploadDialog(
    document.querySelector(uploadDialogSelector),
    {
      i18n: i18nUpload,
      onOpen: () => editor.commands.toggleDialog(true),
      onClose: () => editor.chain().toggleDialog(false).focus(null, { scrollIntoView: false }).run()
    }
  );
};
const uploadImage = (image, uploadUrl) => __async(void 0, null, function* () {
  const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  const data = new FormData();
  data.append("image", image);
  const i18n = getDictionary("editor.extensions.image");
  return new Promise((resolve, reject) => {
    fetch(uploadUrl, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: { "X-CSRF-Token": token },
      body: data
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return new Promise((responseResolve) => responseResolve({ message: i18n.uploadError }));
    }).then(
      (json) => resolve(__spreadValues({ title: fileNameToTitle(image.name) }, json))
    ).catch(reject);
  });
});
const filterImages = (files, contentTypes) => {
  return Array.from(files).filter(
    (file) => {
      if (contentTypes instanceof RegExp) {
        return contentTypes.test(file.type);
      } else if (contentTypes instanceof Array) {
        return contentTypes.includes(file.type);
      }
      return contentTypes === file.type;
    }
  );
};
export default Image.extend({
  addOptions() {
    var _a;
    return __spreadProps(__spreadValues({}, (_a = this.parent) == null ? void 0 : _a.call(this)), {
      contentTypes: /^image\/(jpe?g|png|svg|webp)$/i,
      uploadImagesPath: null,
      uploadDialogSelector: null
    });
  },
  addAttributes() {
    var _a;
    return __spreadProps(__spreadValues({}, (_a = this.parent) == null ? void 0 : _a.call(this)), {
      width: { default: null }
    });
  },
  addCommands() {
    var _a;
    const i18n = getDictionary("editor.extensions.image");
    const uploadDialog = createImageUploadDialog(this.editor, this.options);
    return __spreadProps(__spreadValues({}, (_a = this.parent) == null ? void 0 : _a.call(this)), {
      imageDialog: () => (_0) => __async(this, [_0], function* ({ dispatch }) {
        if (dispatch) {
          let { src, alt, width } = this.editor.getAttributes("image");
          this.editor.commands.toggleDialog(true);
          const dialogState = yield uploadDialog.toggle({ src, alt }, {
            inputLabel: i18n.altLabel,
            uploadHandler: (file) => __async(this, null, function* () {
              return uploadImage(file, this.options.uploadImagesPath);
            })
          });
          this.editor.commands.toggleDialog(false);
          if (dialogState !== "save") {
            this.editor.commands.focus(null, { scrollIntoView: false });
            return false;
          }
          if (uploadDialog.getValue("src") !== src) {
            width = null;
          }
          src = uploadDialog.getValue("src");
          alt = uploadDialog.getValue("alt");
          return this.editor.chain().setImage({ src, alt, width }).focus(null, { scrollIntoView: false }).run();
        }
        return true;
      })
    });
  },
  addNodeView() {
    return createNodeView(this);
  },
  parseHTML() {
    return [{ tag: "div[data-image] img[src]:not([src^='data:'])" }];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      { "class": "editor-content-image", "data-image": "" },
      [
        "img",
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
      ]
    ];
  },
  addProseMirrorPlugins() {
    const editor = this.editor;
    const { uploadImagesPath, contentTypes } = this.options;
    const handleUploadedImages = (uploadedImages) => {
      uploadedImages.forEach((imageData) => {
        if (!imageData.url) {
          return;
        }
        editor.commands.setImage({ src: imageData.url, alt: imageData.title });
      });
    };
    return [
      new Plugin({
        props: {
          handlePaste(view, event) {
            const items = (event.clipboardData || event.originalEvent.clipboardData).items;
            const images = filterImages(items, contentTypes);
            if (images.length < 1) {
              return false;
            }
            Promise.all(images.map((item) => uploadImage(item.getAsFile(), uploadImagesPath))).then((uploadedImages) => {
              handleUploadedImages(uploadedImages);
            });
            return true;
          },
          handleDoubleClick() {
            if (!editor.isActive("image")) {
              return false;
            }
            editor.chain().focus().imageDialog().run();
            return true;
          },
          handleDOMEvents: {
            drop(view, event) {
              var _a;
              const position = view.posAtCoords({ left: event.clientX, top: event.clientY });
              const files = (_a = event == null ? void 0 : event.dataTransfer) == null ? void 0 : _a.files;
              if (!files || files.length < 1) {
                return;
              }
              const images = filterImages(files, contentTypes);
              if (images.length < 1) {
                return;
              }
              event.preventDefault();
              editor.chain().focus().setTextSelection(position.pos).run();
              Promise.all(images.map((image) => uploadImage(image, uploadImagesPath))).then((uploadedImages) => {
                handleUploadedImages(uploadedImages);
              });
            }
          }
        }
      })
    ];
  }
});
