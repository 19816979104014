"use strict";
import { Plugin } from "prosemirror-state";
import CharacterCount from "@tiptap/extension-character-count";
export default CharacterCount.extend({
  addProseMirrorPlugins() {
    var _a;
    const limit = this.options.limit;
    const plugins = (_a = this.parent) == null ? void 0 : _a.call(this);
    if (limit === 0 || limit === null || !limit) {
      return plugins;
    }
    const { storage } = this.editor;
    return [
      ...plugins,
      new Plugin({
        props: {
          handleKeyDown(view, event) {
            if (event.key === "Enter") {
              return storage.characterCount.characters() >= limit;
            }
            return false;
          }
        }
      })
    ];
  }
});
