"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Extension } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import CodeBlock from "@tiptap/extension-code-block";
import Underline from "@tiptap/extension-underline";
import CharacterCount from "src/decidim/editor/extensions/character_count";
import Bold from "src/decidim/editor/extensions/bold";
import Dialog from "src/decidim/editor/extensions/dialog";
import Hashtag from "src/decidim/editor/extensions/hashtag";
import Heading from "src/decidim/editor/extensions/heading";
import OrderedList from "src/decidim/editor/extensions/ordered_list";
import Image from "src/decidim/editor/extensions/image";
import Indent from "src/decidim/editor/extensions/indent";
import Link from "src/decidim/editor/extensions/link";
import Mention from "src/decidim/editor/extensions/mention";
import VideoEmbed from "src/decidim/editor/extensions/video_embed";
import Emoji from "src/decidim/editor/extensions/emoji";
export default Extension.create({
  name: "decidimKit",
  addOptions() {
    return {
      characterCount: { limit: null },
      heading: { levels: [2, 3, 4, 5, 6] },
      link: { allowTargetControl: false },
      videoEmbed: false,
      image: {
        uploadDialogSelector: null,
        uploadImagesPath: null,
        contentTypes: /^image\/(jpe?g|png|svg|webp)$/i
      },
      hashtag: false,
      mention: false,
      emoji: false
    };
  },
  addExtensions() {
    const extensions = [
      StarterKit.configure({
        heading: false,
        bold: false,
        orderedList: false,
        codeBlock: false
      }),
      CharacterCount.configure(this.options.characterCount),
      Link.configure(__spreadValues({ openOnClick: false }, this.options.link)),
      Bold,
      Dialog,
      Indent,
      OrderedList,
      CodeBlock,
      Underline
    ];
    if (this.options.heading !== false) {
      extensions.push(Heading.configure(this.options.heading));
    }
    if (this.options.videoEmbed !== false) {
      extensions.push(VideoEmbed.configure(this.options.videoEmbed));
    }
    if (this.options.image !== false && this.options.image.uploadDialogSelector) {
      extensions.push(Image.configure(this.options.image));
    }
    if (this.options.hashtag !== false) {
      extensions.push(Hashtag.configure(this.options.hashtag));
    }
    if (this.options.mention !== false) {
      extensions.push(Mention.configure(this.options.mention));
    }
    if (this.options.emoji !== false) {
      extensions.push(Emoji.configure(this.options.emoji));
    }
    return extensions;
  }
});
