"use strict";
import { Editor } from "@tiptap/core";
import DecidimKit from "src/decidim/editor/extensions/decidim_kit";
import createEditorToolbar from "src/decidim/editor/toolbar";
import { uniqueId } from "src/decidim/editor/common/helpers";
export default function createEditor(container) {
  var _a;
  const DecidimAwesome = window.DecidimAwesome || {};
  console.log("Using DecidimAwesome createEditor");
  const input = container.parentElement.querySelector("input[type=hidden]");
  const label = container.parentElement.querySelector("label");
  const editorContainer = container.querySelector(".editor-input");
  const editorAttributes = { role: "textbox", "aria-multiline": true };
  if (label) {
    const labelId = uniqueId("editorlabel");
    label.setAttribute("id", labelId);
    editorAttributes["aria-labelledby"] = labelId;
  }
  const features = ((_a = container.dataset) == null ? void 0 : _a.toolbar) || "basic";
  const options = JSON.parse(container.dataset.options);
  const { context, contentTypes } = options;
  const decidimOptions = {};
  if (context !== "participant") {
    decidimOptions.link = { allowTargetControl: true };
  }
  if (input.hasAttribute("maxlength")) {
    decidimOptions.characterCount = { limit: parseInt(input.getAttribute("maxlength"), 10) };
  }
  if (features === "basic") {
    decidimOptions.heading = false;
  }
  if (features === "full" || DecidimAwesome.allow_videos_in_editors) {
    decidimOptions.videoEmbed = true;
  }
  if (features === "full" || DecidimAwesome.allow_images_in_editors) {
    const { uploadImagesPath, uploadDialogSelector } = options;
    decidimOptions.image = {
      uploadDialogSelector,
      contentTypes: contentTypes.image,
      uploadImagesPath
    };
  }
  if (container.classList.contains("js-hashtags")) {
    decidimOptions.hashtag = true;
  }
  if (container.classList.contains("js-mentions")) {
    decidimOptions.mention = true;
  }
  if (container.classList.contains("js-emojis")) {
    decidimOptions.emoji = true;
  }
  const editor = new Editor({
    element: editorContainer,
    editorProps: { attributes: editorAttributes },
    content: input.value,
    editable: !input.disabled,
    extensions: [DecidimKit.configure(decidimOptions)]
  });
  const toolbar = createEditorToolbar(editor);
  container.insertBefore(toolbar, editorContainer);
  editor.on("update", () => input.value = editor.getHTML());
  return editor;
}
