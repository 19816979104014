"use strict";
const proxyCall = (proxy, callable) => (...args) => {
  callable(...args);
  return proxy;
};
export default (tag = "div") => {
  const el = document.createElement(tag);
  const proxy = {};
  proxy.dom = proxyCall(proxy, (callback) => callback(el));
  proxy.append = proxyCall(proxy, (element, ...rest) => {
    if (rest.length > 0) {
      proxy.append(element);
      rest.forEach((subEl) => proxy.append(subEl));
      return;
    } else if (!element) {
      return;
    }
    if (element instanceof Function) {
      proxy.append(element());
    } else if ((element == null ? void 0 : element.render) instanceof Function) {
      proxy.append(element.render());
    } else if (element instanceof Node && element.childNodes.length > 0) {
      el.appendChild(element);
    }
  });
  proxy.render = (condition) => {
    if (condition instanceof Function && !condition(el)) {
      return null;
    } else if (condition === false) {
      return null;
    }
    return el;
  };
  return proxy;
};
