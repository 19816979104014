"use strict";
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import createEditor from "src/decidim/decidim_awesome/editor";
if (!window.fbControls) {
  window.fbControls = [];
}
window.fbControls.push(function(controlClass, allControlClasses) {
  const controlTextarea = allControlClasses.textarea;
  class controlRichtext extends controlTextarea {
    /**
     * Class configuration - return the icons & label related to this control
     * @return {JSON} definition object
     */
    static get definition() {
      return {
        icon: "\u{1F4DD}",
        i18n: {
          default: "Rich Text Editor"
        }
      };
    }
    /**
     * configure the richtext editor requirements
     * @return {void}
     */
    configure() {
      window.fbEditors.tiptap = {};
    }
    /**
     * build a div DOM element & convert to a richtext editor
     * @return {DOMElement} DOM Element to be injected into the form.
     */
    build() {
      const _a = this.config, { value, userData } = _a, attrs = __objRest(_a, ["value", "userData"]);
      this.inputId = `${this.id}-input`;
      this.input = this.markup("input", null, {
        name,
        id: this.inputId,
        type: "hidden",
        value: userData && userData[0] || value || ""
      });
      this.editorInput = this.markup("div", null, {
        style: "height: 25rem",
        class: "editor-input"
      });
      const options = this.classConfig && this.classConfig.editorOptions || { "contentTypes": { image: ["image/jpeg", "image/png"] } };
      const wrapperAttrs = {
        "id": attrs.id,
        "name": attrs.name,
        "type": attrs.type,
        "className": "editor-container",
        "data-toolbar": "basic",
        "data-disabled": "false",
        "data-options": JSON.stringify(options)
      };
      this.wrapper = this.markup("div", this.editorInput, wrapperAttrs);
      return this.markup("div", [this.input, this.wrapper], { style: "margin-top: 1rem" });
    }
    /**
     * When the element is rendered into the DOM, execute the following code to initialise it
     * @param {Object} evt - event
     * @return {Object} evt - event
     */
    onRender(evt) {
      if (window.fbEditors.tiptap[this.id]) {
        console.log("destroying editor", window.fbEditors.tiptap[this.id]);
        window.fbEditors.tiptap[this.id].instance.destroy();
      }
      window.fbEditors.tiptap[this.id] = {};
      const editor = window.fbEditors.tiptap[this.id];
      editor.instance = createEditor(this.wrapper);
      return evt;
    }
  }
  controlTextarea.register("richtext", controlRichtext, "textarea");
});
