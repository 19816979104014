"use strict";
import { textblockTypeInputRule } from "@tiptap/core";
import Heading from "@tiptap/extension-heading";
export default Heading.extend({
  addInputRules() {
    return this.options.levels.map((level) => {
      return textblockTypeInputRule({
        find: new RegExp(`^(#{2,${level}})\\s$`),
        type: this.type,
        getAttributes: { level }
      });
    });
  }
});
